export enum EMenu {
  'Implementation_times_list' = '実施回一覧',
  'message' = 'メッセージ',
  'interview_management' = 'スケジュール管理',
  'interview_manual' = '面談マニュアル',
  'face-back_diagnostic_analysis' = 'コンディション診断比較',
  'client_ID_management' = 'クライアントID管理',
  'interviewer_ID_Management' = '面談員ID管理',
  '.com_ID_Management' = '運営事務局ID管理',
  'career_memo_management' = 'キャリアメモ管理',
  'myPage' = 'TOP/予定管理',
  'Data_download' = 'データダウンロード',
  'analyze_omoteura-result' = 'コンディション診断レポート',
  'list_interview' = '面談一覧',
}

export enum EQualification {
  'A' = 'A：協調性（警戒心欠如）',
  'B' = 'B：環境順応性（妥協的）',
  'C' = 'C：優柔性（決断力欠如）',
  'D' = 'D：謙譲性（劣等感）',
  'E' = 'E：自省心（懐疑的）',
  'F' = 'F：規律性（依存的）',
  'G' = 'G：固執性（心理的圧迫）',
  'H' = 'H：情動性（感情的）',
  'I' = 'I：感受性（神経質）',
  'J' = 'J：自己信頼性（理想主義）',
  'K' = 'K：革新性（衝動的）',
  'L' = 'L：活動性（性急）',
  'M' = 'M：積極性（攻撃的）',
  'N' = 'N：指導性（自尊心）',
  'O' = 'O：創造性（反発的）',
  'P' = 'P：社交性（自己顕示）',
}

export enum ETypeToast {
  Success = '成功されました',
  Error = 'エラーになりました',
}
export enum ENameAlertChart {
  New = 'New',
  Melancholy = 'Melancholy',
  Communication = 'Communication',
  PowerHarassment = 'Power Harassment',
  CarelessMistake = 'Careless Mistake',
  BreakageOfContract = 'Breakage of Contract',
  TroubleMaker = 'Trouble Maker',
  DysthymicDisorder = 'Dysthymic disorder',
}

export enum EElementId {
  RedAlert = 'RedAlert',
  YellowAlert = 'YellowAlert',
  Fulfillment23 = 'table23Fulfillment',
}

export enum EStatusReport {
  Public = 'public',
  UnPublic = 'unPublic',
}
