import { Spin } from 'antd';
import React, { ReactElement } from 'react';

type Props = {
  children: ReactElement;
  loading: boolean;
  className?: string;
  tip?: string;
  size?: 'small' | 'default' | 'large';
  progressPdfn?: number;
};

const SpinnerLoading = ({ children, loading, size = 'large', progressPdfn }: Props) => {
  return (
    <Spin
      spinning={loading}
      size={size}
      tip={progressPdfn ? <>ダウンロード{progressPdfn}%</> : <></>}
    >
      {children}
    </Spin>
  );
};

export default SpinnerLoading;
