import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { employeeWebApi } from '../../api/employeeWebApi';
import moment from 'moment/moment';

export type DataSuffType = {
  period: string;
  workPoint: string;
  otherAtWorkPoint: string;
  status?: string;
};

export type EmployeeWeb = {
  loading: boolean;
  dataSuff: [DataSuffType];
  userInfo: {
    isFirstLogin: boolean;
    isJoinEvent: boolean;
    email: string;
    password: string;
    isDateOfBirth: boolean;
  };
  currenEvent: {
    subStep: number;
    step: number;
    step11: boolean;
    step12: boolean;
    step13: boolean;
    step21: boolean;
    step22: boolean;
    step23: boolean;
    step31: boolean;
    step41: boolean;
    eventInfos: {
      endDate: string;
      eventName: string;
      startDate: string;
      _id: string;
    };
    haveDiagnosisResults?: boolean;
  };
};

const initialState: EmployeeWeb = {
  loading: false,
  dataSuff: [{ period: '', workPoint: '', otherAtWorkPoint: '' }],
  currenEvent: {
    step: 0,
    subStep: 0,
    step11: false,
    step12: false,
    step13: false,
    step21: false,
    step22: false,
    step23: false,
    step31: false,
    step41: false,
    eventInfos: {
      endDate: '',
      eventName: '',
      startDate: '',
      _id: '',
    },
  },
  userInfo: {
    isFirstLogin: false,
    isJoinEvent: false,
    email: '',
    password: '',
    isDateOfBirth: false,
  },
};

export const updateInfoEmployee = createAsyncThunk(
  'employeeWeb/updateInfoEmployee',
  async ({ id, body, fnc }: { id: string; body: {}; fnc: () => void }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .updateInfoEmployee(id, body)
      .then(() => {
        thunkAPI.dispatch(setLoading(false));
        fnc();
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const saveAnswerOmoteuraEmployeeWeb = createAsyncThunk(
  'employeeWeb/saveAnswerOmoteuraEmployeeWeb',
  async (
    { id, answer, eventId, fnc }: { id: string; answer: string; eventId: string; fnc },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebSaveAnswerApi(id, eventId, answer)
      .then(() => {
        thunkAPI.dispatch(setLoading(false));
        fnc();
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const getSuffEmployeeWeb = createAsyncThunk(
  'employeeWeb/getSuffEmployeeWeb',
  async ({ id }: { id: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebGetSuffApi(id)
      .then((response) => {
        thunkAPI.dispatch(setDataSuff(response.data.data));
        thunkAPI.dispatch(setLoading(false));
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const getCurrentEventEmployeeWeb = createAsyncThunk(
  'employeeWeb/getCurrentEventEmployeeWeb',
  async ({ id }: { id: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebGetCureentEventApi(id)
      .then((response) => {
        thunkAPI.dispatch(setDataCurrentEvent(response.data.data));
        thunkAPI.dispatch(setLoading(false));
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const setCurrentEventEmployeeWeb = createAsyncThunk(
  'employeeWeb/setCurrentEventEmployeeWeb',
  async ({ id, body, eventId }: { id: string; body; eventId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebSetCureentEventApi(id, body, eventId)
      .then(() => {
        thunkAPI.dispatch(setLoading(false));
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const getUserInfoEmployeeWeb = createAsyncThunk(
  'employeeWeb/getUserInfoEmployeeWeb',
  async ({ id, fnc }: { id: string; fnc }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebGetUserInfoApi(id)
      .then((response) => {
        thunkAPI.dispatch(setDataUserInfo(response.data.data));
        thunkAPI.dispatch(setLoading(false));
        fnc();
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const setSuffEmployeeWeb = createAsyncThunk(
  'employeeWeb/setSuffEmployeeWeb',
  async ({ body, fnc }: { body; fnc }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi.employeeWebSetSuffApi(body);
    fnc();
  },
);

export const setChangePass = createAsyncThunk(
  'employeeWeb/setChangePass',
  async ({ body, fnc }: { body; fnc }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeWebApi
      .employeeWebChangePass(body)
      .then(() => {
        fnc();
        thunkAPI.dispatch(setLoading(false));
      })
      .catch(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

const slice = createSlice({
  name: 'employeeWeb',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setDataSuff: (state, action: PayloadAction<any>) => {
      const handleDataSuff = () => {
        if (!action.payload.length) {
          return [
            {
              workPoint: '',
              period: '入職時',
              otherAtWorkPoint: '',
              status: 'new',
            },
            {
              workPoint: '',
              period: moment().format('YYYY/MM/DD'),
              otherAtWorkPoint: '',
              status: 'new',
            },
          ];
        } else {
          return [
            ...action.payload,
            {
              workPoint: '',
              period: moment().format('YYYY/MM/DD'),
              otherAtWorkPoint: '',
              status: 'new',
            },
          ];
        }
      };

      state.dataSuff = handleDataSuff() as any;
    },

    setDataUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },

    clearDataWebChecking: (state) => {
      state.dataSuff = initialState.dataSuff;
      state.currenEvent = initialState.currenEvent;
      state.userInfo = initialState.userInfo;
    },

    setDataCurrentEvent: (state, action: PayloadAction<any>) => {
      state.currenEvent = action.payload;
    },
  },
});

export const { actions, reducer: EmployeeWebReducer } = slice;
export const {
  setLoading,
  setDataSuff,
  setDataUserInfo,
  setDataCurrentEvent,
  clearDataWebChecking,
} = actions;
export default EmployeeWebReducer;
