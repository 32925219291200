import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  convertValueToString,
  listTextDirectionCoordinates,
  replacePathParams,
} from 'helpers/funcs';
import { RootState } from 'types/RootState';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getListImplementByEmployee,
  getListImplementByEmployeeAndConsultant,
  setIsShowListEvent,
} from '../../../store/implements/implementsSlice';
import {
  clearAllDataOmoteura,
  getListFeedbacksCom,
  getListOmoteura,
} from '../../../store/omoteura/omoteuraSlice';
import { getUserFromCookie } from '../../../helpers/cookies';
import { EPath } from '../../routes/routesConfig';
import { getListFeedback } from 'store/consultantInterview/consultantInterview';

export const useServices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listOmoteura, infoEmployee, infoInterview, loading } = useSelector(
    (state: RootState) => state.omoteura,
  );
  const [selectedLabel, setSelectedLabel] = useState(0);
  const { employeeId, eventId } = useParams();
  const { listImplementByEmployee } = useSelector((state: RootState) => state.implement);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [temStep, setTemStep] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [eventSelected, setEventSelected] = useState<string>(convertValueToString(eventId));
  const location = useLocation();
  const [eventSelectedConsultant, setEventSelectedConsultant] = useState<string>(
    convertValueToString(location.pathname.split('/')[4]),
  );

  const currentUser = getUserFromCookie();

  useEffect(() => {
    eventId && setEventSelected(eventId);
  }, [eventId]);

  useEffect(() => {
    dispatch(setIsShowListEvent(true));
  }, []);

  useEffect(() => {
    if (employeeId && currentUser) {
      if (currentUser.consultant) {
        dispatch(
          getListImplementByEmployeeAndConsultant({
            employeeId,
            consultantId: currentUser.consultant._id,
          }),
        );
      } else {
        dispatch(getListImplementByEmployee(convertValueToString(employeeId)));
      }
    }
  }, []);

  useEffect(() => {
    if (eventId && !location.pathname.includes('interviews/omoteura-diagnosis-result')) {
      dispatch(
        getListOmoteura({ employeeId: employeeId, eventId: convertValueToString(eventSelected) }),
      );

      if (currentUser.com) {
        dispatch(getListFeedbacksCom({ employeeId, eventId: eventSelected }));
      }
    } else if (eventId && currentUser.consultant) {
      dispatch(getListFeedback({ consultantId: currentUser.consultant._id, employeeId: employeeId ?? '', eventId: eventSelected }));
    }
  }, [eventSelected]);

  useEffect(() => {
    setDirection({
      ...direction,
      value: Number(listOmoteura[listOmoteura.length - 1]?.balance1),
      shortDescription: listOmoteura[listOmoteura.length - 1]?.['short-description'][0],
      pointExplanation: listOmoteura[listOmoteura.length - 1]?.descriptions[0],
    });
  }, [listOmoteura]);

  const [direction, setDirection] = useState({
    text: listTextDirectionCoordinates[0],
    value: Number(listOmoteura[0]?.balance1),
    shortDescription: '',
    pointExplanation: listOmoteura[0]?.descriptions[0],
    index: 0,
  });
  const handleChangeDirection = (index: number) => {
    setDirection({
      text: listTextDirectionCoordinates[index],
      value: Number(listOmoteura[listOmoteura.length - 1]?.[`balance${index + 1}`]),
      shortDescription: listOmoteura[listOmoteura.length - 1]?.['short-description'][index],
      pointExplanation: listOmoteura[listOmoteura.length - 1]?.descriptions[index],
      index: index,
    });
  };

  const handleSetEventSelected = (id: string) => {
    setEventSelected(id);
    navigate(
      replacePathParams(EPath.omoteuraDiagnosi, {
        employeeId: convertValueToString(employeeId),
        eventId: convertValueToString(id),
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearAllDataOmoteura);
    };
  }, []);

  const answerCurrent = useMemo(() => {
    return listOmoteura.find((item) => item.eventId === eventId)?.answer;
  }, [eventId, listOmoteura]);

  return {
    t,
    handleChangeDirection,
    listOmoteura,
    direction,
    infoEmployee,
    selectedLabel,
    setSelectedLabel,
    listImplementByEmployee,
    eventId,
    currentUser,
    setTemStep,
    setOpenModalConfirm,
    openModalConfirm,
    temStep,
    step,
    setStep,
    eventSelected,
    handleSetEventSelected,
    infoInterview,
    eventSelectedConsultant,
    setEventSelectedConsultant,
    answerCurrent,
    loading,
  };
};
