import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ETypeToast } from 'app/enum';

export type ToastState = {
  label: string;
  isShow: boolean;
  type: ETypeToast;
  loadingApp?: boolean;
};

const initialState: ToastState = {
  label: '',
  isShow: false,
  type: ETypeToast.Success,
  loadingApp: false,
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<ToastState>) {
      state.label = action.payload.label;
      state.isShow = true;
      state.type = action.payload.type;
    },
    hiddenToast(state) {
      state.isShow = false;
      state.type = ETypeToast.Success;
    },
    setLoadingApp(state, action: PayloadAction<boolean>) {
      state.loadingApp = action.payload;
    },
  },
});

export const setShowToast = createAsyncThunk(
  'toast/showToast',
  async (params: { label: string; type: ETypeToast }, thunkAPI) => {
    thunkAPI.dispatch(
      showToast({
        label: params.label,
        isShow: true,
        type: params.type,
      }),
    );
  },
);

export const setHiddenToast = createAsyncThunk('toast/hiddenToast', async (_, thunkAPI) => {
  thunkAPI.dispatch(hiddenToast());
});

export const { actions, reducer: toastReducer } = slice;
export const { showToast, hiddenToast, setLoadingApp } = actions;
export default toastReducer;
